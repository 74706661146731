

















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import CountriesData from '@/assets/json/Countries.json'
import { VueTelInput } from 'vue-tel-input'
import MemberCard from './member-card.vue'

@Component({
  components: {
    VueTelInput,
    MemberCard
  },
  methods: {
    ...mapActions({
      setNationality: 'Cart/setNationality',
      setGender: 'Cart/setGender',
      setTel: 'Cart/setTel',
      clearDiscount: 'Cart/clearDiscount',
      validateCode: 'Cart/validateCode',
      getStepPricing: 'Cart/getStepPricing'
    })
  },
  computed: {
    ...mapGetters({
      nationality: 'Cart/nationality',
      gender: 'Cart/gender'
    }),
    ...mapState({
      cartTel: (state: any) => state.Cart.tel,
      promotionCode: (state: any) => state.Cart.promotionCode
    })
  }
})
export default class CustomerInfo extends Vue {
  @Prop()
  readonly memberTel!: string

  @Watch('memberTel', { deep: true, immediate: true })
  async onMemberTelChanged (newVal: any, oldVal: any): Promise<void> {
    if (newVal) this.tel = newVal
  }

  @Watch('nationality', { deep: true, immediate: true })
  async onNationalityChanged (newVal: any, oldVal: any): Promise<void> {
    if (newVal) this.nationInput = newVal
  }

  readonly setNationality!: any

  readonly setGender!: any

  public readonly nationality!: any

  public readonly gender!: any

  public memberData: any = null

  public nationalities = [
    {
      nation: 'Thailand',
      text: 'ไทย'
    },
    {
      nation: 'Hong Kong',
      text: 'ฮ่องกง'
    },
    {
      nation: 'Taiwan',
      text: 'ไต้หวัน'
    },
    {
      nation: 'Macau',
      text: 'มาเก๊า'
    },
    {
      nation: 'Singapore',
      text: 'สิงคโปร์'
    },
    {
      nation: 'China',
      text: 'จีน'
    },
    {
      nation: 'Vietnam',
      text: 'เวียดนาม'
    },
    {
      nation: 'Malaysia',
      text: 'มาเลเซีย'
    },
    {
      nation: 'Philippines',
      text: 'ฟิลิปปินส์'
    },
    {
      nation: 'Laos',
      text: 'ลาว'
    },
    {
      nation: 'Myanmar',
      text: 'เมียนม่า'
    },
    {
      nation: 'Indonesia',
      text: 'อินโดนีเซีย'
    },
    {
      nation: 'Cambodia',
      text: 'กัมพูชา'
    },
    {
      nation: 'Russia',
      text: 'รัสเซีย'
    },
    {
      nation: 'Ukraine',
      text: 'ยูเครน'
    }
  ]

  public genders = [
    {
      text: 'ชาย',
      value: 'male'
    },
    {
      text: 'หญิง',
      value: 'female'
    }
  ]

  public nationInput = 'Thailand'

  setTel!: any

  cartTel!: any

  validateCode!: any

  clearDiscount!: any

  promotionCode!: any

  loading = false

  getStepPricing!: any

  code = ''

  tel = ''

  hideInputPhoneNumber = false

  async mounted (): Promise<void> {
    await this.getStepPricing()
    if (this.promotionCode != null) {
      this.code = this.promotionCode.code
    }
    this.tel = this.cartTel === null ? '' : this.cartTel
  }

  get type (): any {
    return this.$route.query.type ? this.$route.query.type : 'receipt'
  }

  get countries (): any {
    const filtered = CountriesData.filter((c) => (
      c.country
        .toString()
        .toLowerCase()
        .indexOf(this.nationInput.toLowerCase()) >= 0
    ))
    return filtered.map((c) => c.country)
  }

  selectNationality (nation: string, isRedirect = false): void {
    if (!nation) {
      return
    }

    const found = CountriesData.find((c) => c.country.toLowerCase() === nation.toLowerCase())

    if (!found && isRedirect) {
      this.$buefy.toast.open({ message: `ไม่พบประเทศ ${nation}`, type: 'is-danger' })
      return
    }

    this.setNationality(nation)
    this.nationInput = nation
  }

  selectGender (payload: string, isRedirect = false): void {
    if (!payload) {
      return
    }

    const found = this.genders.find((c) => c.value.toLowerCase() === payload.toLowerCase())

    if (!found && isRedirect) {
      this.$buefy.toast.open({ message: `ไม่พบเพศ ${payload}`, type: 'is-danger' })
      return
    }

    this.setGender(payload)
  }

  gotoPayment (): void {
    if (!this.nationality || !this.gender) {
      return
    }

    this.$router.push({ name: 'Payment' })
  }

  onInputTel (event: any, option: any): void {
    if (option.valid) {
      // this.hideInputPhoneNumber = true
      this.setTel(option.number)
    } else {
      this.setTel(null)
      this.code = ''
      this.clearDiscount()
    }
  }

  get isDisableApplyBtn (): boolean {
    if (this.cartTel === null) {
      return true
    }
    if (this.promotionCode != null) {
      return true
    }
    return false
  }

  async validDateCodeHandle (): Promise<void> {
    this.loading = true
    const result = await this.validateCode(this.code.toUpperCase())
    if (result.success) {
      this.$buefy.dialog.alert({
        message: 'This code can be use.',
        type: 'is-success',
        hasIcon: true,
        icon: 'check-circle-outline',
        iconPack: 'mdi'
      })
    } else {
      this.$buefy.dialog.alert({
        type: 'is-danger',
        hasIcon: true,
        icon: 'close-circle-outline',
        iconPack: 'mdi',
        message: `<span>${result.message}</span>`
      })
    }
    this.loading = false
  }

  editPhone (): void {
    this.setTel(null)
    this.tel = ''
    this.code = ''
    // this.hideInputPhoneNumber = false
  }
}
