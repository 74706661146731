
































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

interface RemoveItemInterface {
  skuId: string
  removeAll?: boolean
}

@Component({
    computed: {
      ...mapGetters({
        fullPrice: 'Cart/fullPrice'
      })
    }
})
export default class CartItems extends Vue {
  showFullPriceFormat!: any

  calculatedItems!: any[]

  @Prop()
  groupedItems!: any[]

  calcStock = (onHandQty: number, onReservedQty: number): number => (onHandQty - onReservedQty)

  removeCartItem (skuId: string): void {
    this.$emit('removeCartItem', skuId)
  }

  addItemToCart (code: string): void {
    this.$emit('addItemToCart', code)
  }
}

